@font-face {
	font-family: 'GHEA Grapalat';
	src: local('GHEAGrapalat'), url(fonts/GHEAGrapalat/gheagrapalat.otf) format('truetype');
}
@font-face {
	font-family: 'GHEA Grapalat bold';
	src: local('GHEAGrapalat'), url(fonts/GHEAGrapalat/gheagrapalat_bold.otf) format('truetype');
}
@font-face {
	font-family: 'MontserratArm';
	src: local('MontserratArm'), url(fonts/MontserratArm/Montserratarm-Regular.otf) format('truetype');
}

body {
	margin: 0;
	font-family: 'MontserratArm', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
	display: initial !important;
	opacity: 0.3;
}

.ant-tooltip-inner {
	white-space: pre-wrap;
}
