.header {
	display: flex;
	justify-content: space-between;
	background: white !important;
	border-left: 1px solid rgba(17, 16, 16, 0.1);
	border-bottom: 1px solid rgba(17, 16, 16, 0.1);
	padding: 0 20px !important;
	box-sizing: border-box;
	overflow: hidden;
}

.right-actions {
	display: flex;
	align-items: center;
	& > * {
		margin-left: 15px !important;
	}
	.notification-count {
		position: absolute;
		z-index: 111;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		top: 2px;
		left: 10px;
		background: @primary-color;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		color: white;
	}
	.lang-link {
		width: 30px;
		display: flex;
		align-items: center;
		height: 100%;
		font-weight: 600;
		color: @ui-black;
	}
	.profile-link {
		display: flex;
		align-items: center;
		height: 100%;
		color: @ui-black;
	}
}

.notifications-bell {
	color: @ui-black;
}

.notification-dropdown {
	.ant-dropdown-menu-title-content {
		display: flex;
		justify-content: space-between;
	}
}

.lang-dropdown {
	padding: 10px 0 !important;
	li {
		padding: 10px 20px;
	}
}

.profile-dropdown {
	padding: 10px 0 !important;
	width: 200px;
	li {
		padding: 10px 20px;
		color: @ui-black;
	}
}

.ant-breadcrumb {
	display: flex;
	align-items: center;
	span {
		cursor: default;
	}
}

// @media screen and (max-width: 1066px) {
// 	.menu {
// 		width: 70% !important;
// 	}
// }

@media (max-width: 768px) {
	.header {
		ul {
			margin-left: 10px;
		}
	}
}

@primary-color: #6C5DD3;@ui-blue: #38456F;@ui-black: #111010;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;